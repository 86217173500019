<template>
    <section>
        <p>ceci est un test</p>
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <carousel-meb :slides="json.data['carousel-meb']" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import carouselMeb from '../../components/element/carousel-meb.vue'
import json from '../json/testCarouselText.json'
export default {
    name: 'edit-carousel-meb',
    components: {
        carouselMeb
    },
    data () {
        return {
            json: json
        }
    }
}
</script>